import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/'

Vue.use(VueRouter)

const routes = [
  {
    path: '/create-pay/:hash',
    name: 'create-pay',
    meta: {
      title: 'Данные банковской карты'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/BankCard.vue')
  },
  {
    path: '/payment-sbp/:hash',
    name: 'SBPPayIn',
    component: () => import(/* webpackChunkName: "about" */ '../views/SBPPayIn.vue'),
    meta: {
      sidebar: true,
      title: 'Оплатить с СБП'
    }
  },
  {
    path: '/success',
    name: 'success',
    meta: {
      title: 'Ваш платеж принят в обработку'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/SuccessPayment.vue')
  },
  {
    path: '/',
    name: 'payment-info-root',
    component: () => import(/* webpackChunkName: "payment-info" */ '../views/PaymentInfo.vue'),
    meta: {
      title: 'Оплата по займу'
    }
  },
  {
    path: '/:hash',
    name: 'payment-info',
    component: () => import(/* webpackChunkName: "payment-info" */ '../views/PaymentInfo.vue'),
    meta: {
      title: 'Оплата по займу'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('CLEAR_ERRORS')
  next()
})

export default router
