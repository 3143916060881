<template>
   <div class="socials">
     <div class="socials__list">
       <div class="socials__item" v-for="(social,index) in socials" :key="index">
         <a :href="social.link" target='_blank' :title="social.name" class="socials__link">
           <fai :icon='social.icon'/>
         </a>
       </div>
     </div>
   </div>
</template>

<script>
export default {
  name: 'Socials',
  data () {
    return {
      socials: [
        {
          name: 'ВКонтакте',
          icon: ['fab', 'vk'],
          link: 'https://vk.com/dengazaim'
        },
        {
          name: 'Телеграм',
          icon: ['fab', 'telegram-plane'],
          link: 'https://t-do.ru/Dengaru_bot'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  @import "@/styles/vars.scss";
  .socials {
    &__list {
      display: flex;
    }

    &__item {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
    &__link {
      text-decoration: none;
      background-color: $color-red;
      color: #fff;
      padding: 10px 11px;
      border-radius: 50%;
      width: 42px;
      text-align: center;
      justify-content: center;
      display: flex;
      transition: all .2s;
      font-size: 18px;

      &:hover {
        background-color: $color-dark-blue;
      }

      .v-icon {
        color: #fff !important;
        font-size: 16px;
      }
    }
  }
</style>
