export default {
  best2PayErrors: {
    2: 'Неверный срок действия Банковской карты.',
    3: 'Платёж отклонён. Пожалуйста, обратитесь в Банк, выпустивший Вашу карту.',
    4: 'Платёж отклонён. Пожалуйста, обратитесь в Банк, выпустивший Вашу карту.',
    5: 'Платёж отклонён. Пожалуйста, обратитесь в Банк, выпустивший Вашу карту.',
    6: 'Недостаточно средств на счёте Банковской карты.',
    12: 'Указано неверное значение секретного кода карты.',
    defaultMessage: 'Произошла ошибка, попробуйте еще раз.'
  }
}
