<template>
  <div class="base-input" :class="{ 'base-input--error': hasErrors}">
    <div class="base-input__wrapper">
      <slot :errors="activeErrorMessages" />
    </div>
  </div>
</template>

<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor'

export default {
  name: 'BaseInput',
  mixins: [singleErrorExtractorMixin]
}
</script>

<style lang="scss">
@import "@/styles/vars.scss";

.base-input {
  margin-bottom: 15px;

  .v-input {
    padding-top: 0;
    margin-top: 0;
    border-color: transparent !important;
    color: transparent;

    &--is-disabled {
      input {
        background-color: rgba(234, 229, 229, 0.36);
      }
    }

    &__slot {
      &:before {
        border: 0 !important
      }
    }
  }

  .v-text-field__slot {
    background-color: #fff;
    box-shadow: 0 0 15px rgba(1,23,40,.1);
    border-radius: 16px;
    min-height: 56px;
    border: 0px solid transparent;

    label {
      top: 19px;
      left:32px!important;
      @media screen and (max-width: 768px){
        font-size: 14px;
      }

      &.v-label--active {
        transform: translateY(-18px) scale(1);
        left: 30px!important;
        font-size: 12px;

        @media screen and (max-width: 768px){
          font-size: 10px;
        }
      }
    }

    input {
      &::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
      }

      padding: 16px 32px;
      max-height: 100%;
      border-radius: 16px;
      color: $color-dark-blue;
      font-size: 16px;
      @include ff_medium
    }

    &:after{
      display: none !important;
    }
  }

  .v-messages {
    &__message {
      padding-left: 15px;
    }
  }

  &--error {
    .v-text-field__slot {
      border-color: $color-orange;
    }
    .v-messages {
      &__message {
        color:  $color-orange;
      }
    }
  }
}
</style>
