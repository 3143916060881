<template>
  <div id="app">
    <div class="wrapper">
      <div class="page-container">
        <base-header/>
        <div class="main">
          <div class="container">
            <page-title v-if="$route.meta.title">{{$route.meta.title}}</page-title>
            <router-view/>
          </div>
        </div>
      </div>
      <base-footer />
    </div>
  </div>
</template>

<script>
import BaseFooter from '@/components/layout/BaseFooter.vue'
import BaseHeader from '@/components/layout/BaseHeader.vue'
import PageTitle from '@/components/PageTitle.vue'

export default {
  name: 'PaymentInfo',
  components: {
    BaseFooter,
    BaseHeader,
    PageTitle
  }
}
</script>
