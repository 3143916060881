const messages = {
  userAgreement: 'Подтвердите обработку персональных данных.',
  numeric: 'Требуется ввести цифры.',
  ru: 'Допустимы только символы кириллицы.',
  vinNumber: 'Некорректный VIN номер.',
  ptsSeria: 'Некорректный номер ПТС.',
  snils: 'Некорректный СНИЛС.',
  email: 'Некорректный E-mail.',
  isMaturity: 'Некорректная дата.',
  currentYear: 'Некорректная дата выдачи.',
  floatSumm: 'Введена некорректная сумма платежа.',
  maxSumm: 'Превышена максимальная сумма оплаты',
  minSumm: 'Сумма для оплаты должна быть больше 0.',
  correctPassportDateIssue: 'Некорректная дата выдачи.',
  mainPhoneNumber: 'Дополнительный номер не может совпадать с номером, на который вы зарегистрировались',
  sameAsPassword: 'Пароли не совпадают. Убедитесь, что Вы вводите правильный пароль и попробуйте ещё раз.',
  phone: 'Некорректный номер телефона',
  uniqPhones: 'Номера телефонов не должны совпадать',
  login: 'Логин или пароль не совпадают.',
  validateInn: 'Некорректный ИНН',
  chooseOne: 'Необходимо выбрать ответ',
  invalid: 'Поле заполненно неверно.',
  invalidField: 'Поле {attribute} заполнено неверно.',
  required: 'Поле не заполнено.',
  requiredNamed: 'Поле {attribute} не заполнено.',
  minLength: 'Минимальное количество символов - {min}',
  maxLength: 'Максимальное количество символов - {max}'
}

const attributes = {}

const validationKeys = {}

export {
  messages,
  validationKeys,
  attributes
}
