import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import vuetify from './plugins/vuetify'
import BaseInput from './components/BaseInput'
import BaseForm from './components/BaseForm'
import VModal from 'vue-js-modal'
import VuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor'
import Vuelidate from 'vuelidate'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { messages, attributes, validationKeys } from './helpers/i18n/validatorMessages'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faVk, faTelegramPlane } from '@fortawesome/free-brands-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import './styles/main.scss'

library.add(faVk, faTelegramPlane, faTimes)

Vue.config.productionTip = false

Vue.component('base-input', BaseInput)
Vue.component('base-form', BaseForm)
Vue.component('FormWrapper', templates.FormWrapper)
Vue.component('fai', FontAwesomeIcon)

function getServer () {
  return process.env.VUE_APP_SERVER_URL || document.location.origin
}

const instance = axios.create({
  baseURL: getServer() + '/api/v1/',
  responseType: 'json'
})

Vue.use(VuelidateErrorExtractor, {
  attributes,
  messages,
  validationKeys
})

Vue.use(Vuelidate)
Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true
})

Vue.prototype.$http = instance

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
