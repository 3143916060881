import root from './cardErrorMessages.js'

export default function getMessage (name, dict, defaultMessage = false) {
  if (!name) return

  for (const message in dict) {
    if (+name === +message) {
      return dict[message]
    }
  }

  if (defaultMessage && dict.defaultMessage) {
    return dict.defaultMessage
  }

  return root.default.error
}
