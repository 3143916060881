<template>
   <div class="error">
     <div class="error__wrapper">
       <div class="error__icon">
          <img src="@/assets/unverified.svg" />
       </div>
       <div class="error__text">
         <slot></slot>
       </div>
     </div>
   </div>
</template>

<script>
export default {
  name: 'Error'
}
</script>

<style scoped lang="scss">
@import "@/styles/vars.scss";

.error {
  margin-bottom: 20px;
   &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
   }
   &__icon {
      display: flex;
      margin-right: 5px;
   }
   &__text {
      text-align: center;
      color:$color-orange;
    }
}
</style>
