<template>
  <form class="form" :id="id" @submit.prevent="$emit('onSubmit')">
    <div class="error-box">
      <error
        v-for="(item, index) in errors"
        :key="index"
      >
        <div>{{item[0]}}</div>
      </error>
    </div>
    <div class="form__wrapper" v-if="(errors && errors.error_code) !== 100">
      <div class="form__content">
        <slot/>
      </div>
      <div class="form__footer">
        <slot name="footer"/>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex'
import Error from './Error'

export default {
  name: 'BaseForm',
  computed: {
    ...mapState({
      errors: state => state.errors
    })
  },
  props: {
    title: {
      type: String
    },
    id: String
  },
  components: {
    Error
  }
}
</script>

<style scoped lang="scss">
.form {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__errors {
    margin-bottom: 20px;
  }
}
</style>
